import { Box, Button, Center, Checkbox, CheckboxGroup, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, HStack, Heading, IconButton, Input, Radio, RadioGroup, Stack, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import races from '../data/races.json';
import bodies from '../data/bodies.json';
import clothing from '../data/clothing.json';
import factions from '../data/factions.json';
import { HamburgerIcon } from "@chakra-ui/icons";
import { Canvas } from '../components/Canvas';

export const Main = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef<HTMLButtonElement>(null);

    const [selectedFaction, setSelectedFaction] = useState<string | undefined>("settler");
    const [selectedRace, setSelectedRace] = useState<string | undefined>("human");
    const [selectedTone, setSelectedTone] = useState<string | undefined>("light");
    const [selectedBodyType, setSelectedBodyType] = useState<string | undefined>("male");
    const [selectedClothing, setSelectedClothing] = useState<string[]>(['torsoNone', 'legsNone', 'feetNone']);

    const headMap = [
        {bodyType: 'pregnant', head: 'female'},
        {bodyType: 'teen', head: 'female'}
    ];

    // NOTE - these need to map in Unity
    const parsedHead = headMap.find(b => b.bodyType === selectedBodyType)?.head ?? selectedBodyType;

    const bodyPaths: string[] = [
        `/body/bodies/${selectedBodyType}/universal/${selectedTone}.png`,
    ];

    const clothingPaths = selectedClothing.map(c => `${c}/${selectedBodyType}/white.png`);

    const headPaths: string[] = [
        `/head/heads/${selectedRace}_${parsedHead}/universal/${selectedTone}.png`,
    ];

    const imagePaths = [...bodyPaths, ...clothingPaths, ...headPaths];

    return (<Box flex={1}>
        <Flex bg='blue.600' p={4} flexDir="row" color="white" gap={12}>
            <IconButton isRound={true}
            variant='solid'
            colorScheme='teal' icon={<HamburgerIcon />} onClick={onOpen} aria-label={""}/>
            <Center fontSize={'large'}>Character Customiser</Center>
        </Flex>
        <Canvas imagePaths={imagePaths}/>
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerContent mt={20}>
                <DrawerCloseButton />
                <DrawerHeader>Customise your character</DrawerHeader>

                <DrawerBody>
                    <Menu selectedRace={selectedRace} selectedBodyType={selectedBodyType} selectedFaction={selectedFaction} selectedClothing={selectedClothing} selectedTone={selectedTone}
                    setSelectedFaction={setSelectedFaction} setSelectedBodyType={setSelectedBodyType} setSelectedClothing={setSelectedClothing} setSelectedRace={setSelectedRace} setSelectedTone={setSelectedTone}/>
                </DrawerBody>

                <DrawerFooter>
                    <Button flex={1} variant='outline' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </Box>);
};



const Menu = ({
    selectedRace, 
    selectedBodyType, 
    selectedTone, 
    selectedFaction, 
    selectedClothing,
    setSelectedRace,
    setSelectedBodyType,
    setSelectedTone,
    setSelectedFaction,
    setSelectedClothing,
}:{
    selectedRace?: string, 
    selectedBodyType?: string, 
    selectedTone?: string, 
    selectedFaction?: string, 
    selectedClothing: string[],
    setSelectedRace: (race: string) => void,
    setSelectedBodyType: (body?: string) => void,
    setSelectedTone: (tone?: string) => void,
    setSelectedFaction: (faction: string) => void,
    setSelectedClothing: (clothing: string[]) => void,
}) => {
    // calculated based on selections
    const [availableBodyTypes, setAvailableBodyTypes] = useState<string[]>([]);
    const [availableTones, setAvailableTones] = useState<string[]>([]);
    const [availableClothing, setAvailableClothing] = useState<ClothingItem[]>([]);
    
    // categories
    const [uniqueSlots, setUniqueSlots] = useState<string[]>([]);

    // handle selections
    const handleRaceChange = (value: string) => {
        setSelectedRace(value);
    };

    const handleBodyTypeChange = (value: string) => {
        setSelectedBodyType(value);
    };

    const handleToneChange = (value: string) => {
        setSelectedTone(value);
    };

    const handleFactionChange = (value: string) => {
        setSelectedFaction(value);
    };

    const handleClothingChange = (selectedItems: string[]) => {
        setSelectedClothing(selectedItems);
    };

    // responds to selections
    // bodies from races
    useEffect(() => {
        if (!selectedRace)
        {
            return;
        }

        const filteredBodyTypes = bodies.filter(bodyType =>
            bodyType.supportedRaces.includes(selectedRace))
            .map(bodyType => bodyType.type);

        const filteredTones = races.find(r => r.id)?.tones ?? [];

        setAvailableBodyTypes(filteredBodyTypes);
        setSelectedBodyType(filteredBodyTypes.find(bt => bt === selectedBodyType));

        setAvailableTones(filteredTones);
        setSelectedTone(filteredTones.find(t => t === selectedTone));
    }, [selectedRace]);

    // clothing from races and bodies
    useEffect(() => {
        const filteredClothing = clothing.filter(item =>
            item.compatibility.some(compat =>
                !!selectedBodyType && 
                compat.race === selectedRace && 
                compat.bodyTypes.includes(selectedBodyType)
            )
        );
        setAvailableClothing(filteredClothing);

        // Extract unique slots
        const slots = Array.from(new Set(filteredClothing.map(item => item.slot)));
        setUniqueSlots(slots);

        setSelectedClothing(selectedClothing.filter(sc => !!filteredClothing.find(ac => ac.id === sc)));
    }, [selectedRace, selectedBodyType]);


    // Function to determine if an item is preferred by the selected faction
    const isPreferred = (item: ClothingItem) => {
        if (!selectedBodyType)
        {
            return false;
        }

        return item.preferred.some(pref => 
            pref.race === selectedRace && pref.bodyTypes.includes(selectedBodyType));
    };

    const isFactionPreferred = (item: ClothingItem) => {
        if (!selectedFaction)
        {
            return false;
        }

        return item.preferredFactions.includes(selectedFaction);
    };

    const getItemBackgroundColor = (item: ClothingItem) => {
        if (isPreferred(item) && isFactionPreferred(item)) return "blue.100";
        if (isPreferred(item)) return "green.100";
        return "white";
    };

    // random generation

    // todo - randomise race

    // todo - randomise body type

    // todo - randomise clothing

    return (<Box flex={1}>
        <Heading as="h3" size="md" mb={4}>Faction</Heading>
        <RadioGroup onChange={handleFactionChange} value={selectedFaction}>
            <Stack direction="column">
                {factions.map(faction => (
                    <Radio key={faction.id} value={faction.id}>
                        {faction.label}
                    </Radio>
                ))}
            </Stack>
        </RadioGroup>

        <Heading as="h3" size="md" mb={4} mt={6}>Race</Heading>
        <RadioGroup onChange={handleRaceChange} value={selectedRace}>
            <Stack direction="column">
                {races.map(race => (
                    <Radio key={race.id} value={race.id}>
                        {race.name}
                    </Radio>
                ))}
            </Stack>
        </RadioGroup>
        
        {availableBodyTypes.length > 0 && (
            <Box mt={6}>
                <Heading as="h4" size="md" mb={4}>Body Type</Heading>
                <RadioGroup onChange={handleBodyTypeChange} value={selectedBodyType}>
                    <Stack direction="column">
                        {availableBodyTypes.map(bodyType => (
                            <Radio key={bodyType} value={bodyType}>
                                {bodyType.charAt(0).toUpperCase() + bodyType.slice(1)}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            </Box>
        )}
        {availableTones.length > 0 && (
            <Box mt={6}>
                <Heading as="h4" size="md" mb={4}>Skin Tone</Heading>
                <RadioGroup onChange={handleToneChange} value={selectedTone}>
                    <Stack direction="column">
                        {availableTones.map(tone => (
                            <Radio key={tone} value={tone}>
                                {tone.charAt(0).toUpperCase() + tone.slice(1)}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            </Box>
        )}
            <Heading as="h4" size="md" mb={4} mt={6}>Clothing</Heading>
            <CheckboxGroup value={selectedClothing} onChange={handleClothingChange}>
                {uniqueSlots.map(slot => (
                    <Box key={slot} mt={4}>
                        <Heading as="h5" size="md" mb={4}>{slot.charAt(0).toUpperCase() + slot.slice(1)}</Heading>
                        <Stack>
                            {availableClothing.filter(item => item.slot === slot).map(item => (
                                <Checkbox key={item.id} value={item.id} width="100%" bg={getItemBackgroundColor(item)}>
                                    {item.name}
                                </Checkbox>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </CheckboxGroup>
        </Box>);
};

const CharacterDisplay = () => {
    return (<Box flex={1}>test 2</Box>);
};

type ClothingItem = {
    id: string;
    name: string;
    slot: string; // Using string to accommodate for "feet" and potential future additions
    compatibility: Array<{
        race: string;
        bodyTypes: string[];
    }>;
    preferred: Array<{
        race: string;
        bodyTypes: string[];
    }>;
    preferredFactions: string[];
};