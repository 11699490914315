import { Box, Center, Flex, Heading, VStack } from '@chakra-ui/react';
import React, { useRef, useEffect, useState } from 'react';

export const Canvas = ({imagePaths}:{imagePaths: string[]}) => {
    const mainRef = useRef<HTMLCanvasElement>(null);
    const walkingRef = useRef<HTMLCanvasElement>(null);
    const shootingRef = useRef<HTMLCanvasElement>(null);
    const attackingRef = useRef<HTMLCanvasElement>(null);
    const castingRef = useRef<HTMLCanvasElement>(null);
    const deathRef = useRef<HTMLCanvasElement>(null);

    const universalFrameSize = 256;
    const universalSheetWidth = 3328;
    const universalSheetHeight = 5376;

    const [imageElements, setImageElements] = useState<HTMLImageElement[]>([]);
    const frame = useRef(0);
    const delay = useRef(0);

    const background = "#555555";

    const getContext = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        return context;
    };

    const draw = (frameCount: number, images: HTMLImageElement[]) => {
        delay.current -= 1;
        if (delay.current <= 0)
        {
            delay.current = 10;
            frame.current += 1;
        }

        const ctx = getContext(mainRef);
        const walkingContext = getContext(walkingRef);
        const castingContext = getContext(castingRef);
        const attackingContext = getContext(attackingRef);
        const shootingContext = getContext(shootingRef);
        const deathContext = getContext(deathRef);

        if (!ctx || !walkingContext || !castingContext || !shootingContext || !deathContext || !attackingContext || !mainRef.current)
        {
            return;
        }

        // reset canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        walkingContext.clearRect(0, 0, walkingContext.canvas.width, walkingContext.canvas.height);
        castingContext.clearRect(0, 0, castingContext.canvas.width, castingContext.canvas.height);
        attackingContext.clearRect(0, 0, castingContext.canvas.width, castingContext.canvas.height);
        shootingContext.clearRect(0, 0, shootingContext.canvas.width, shootingContext.canvas.height);
        deathContext.clearRect(0, 0, deathContext.canvas.width, deathContext.canvas.height);

        // draw static images
        images.forEach(image => {
            try
            {
                ctx.drawImage(image, 0, 0, image.width, image.height);
            }
            catch (error)
            {
                console.log(image.src);
            }
        });

        //walking
        drawCell(mainRef.current, walkingContext, frame.current, 8, 8, 0, 1);
        drawCell(mainRef.current, walkingContext, frame.current, 9, 8, 1, 1);
        drawCell(mainRef.current, walkingContext, frame.current, 10, 8, 2, 1);
        drawCell(mainRef.current, walkingContext, frame.current, 11, 8, 3, 1);

        //attacking
        drawCell(mainRef.current, attackingContext, frame.current, 4, 7, 0, 1);
        drawCell(mainRef.current, attackingContext, frame.current, 5, 7, 1, 1);
        drawCell(mainRef.current, attackingContext, frame.current, 6, 7, 2, 1);
        drawCell(mainRef.current, attackingContext, frame.current, 7, 7, 3, 1);

        //shooting
        drawCell(mainRef.current, shootingContext, frame.current, 16, 12, 0, 1);
        drawCell(mainRef.current, shootingContext, frame.current, 17, 12, 1, 1);
        drawCell(mainRef.current, shootingContext, frame.current, 18, 12, 2, 1);
        drawCell(mainRef.current, shootingContext, frame.current, 19, 12, 3, 1);

        //death
        drawCell(mainRef.current, deathContext, frame.current, 20, 6, 0, 0);

        //casting
        drawCell(mainRef.current, castingContext, frame.current, 0, 7, 0);
        drawCell(mainRef.current, castingContext, frame.current, 1, 7, 1);
        drawCell(mainRef.current, castingContext, frame.current, 2, 7, 2);
        drawCell(mainRef.current, castingContext, frame.current, 3, 7, 3);
    };

    const drawCell = (ctxLarge: HTMLCanvasElement, ctxSmall: CanvasRenderingContext2D, frameCount: number, row: number, rowWidth: number, destinationColumn: number, skip = 0) => {
        if (!ctxSmall)
        {
            return;
        }

        const index = frameCount % (rowWidth - skip);

        ctxSmall.drawImage(ctxLarge, universalFrameSize * index + skip * universalFrameSize, 
            universalFrameSize * row, universalFrameSize, 
            universalFrameSize, 
            universalFrameSize * destinationColumn, 
            0, 
            universalFrameSize, 
            universalFrameSize);
    };

    useEffect(() => {
        const images = imagePaths.map(path => {
            const img = new Image();
            img.src = `${process.env.PUBLIC_URL}/spritesheets${path}`;
    
            return img;
        });

        setImageElements(images);
    }, [imagePaths]);

    useEffect(() => {
        const canvas = mainRef.current;
        const context = canvas?.getContext('2d');
        let frameCount = 0;
        let animationFrameId: number;

        const animate = () => {
            frameCount++;
            if (context) {
                draw(frameCount, imageElements);
            }
            animationFrameId = window.requestAnimationFrame(animate);
        };

        animate();

        return () => {
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [draw, imageElements]);

    return (
        <Flex justifyContent="center" w="100%"> {/* Ensures horizontal centering */}
            <VStack>
                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Walking</Heading>
                    <canvas ref={walkingRef} width={universalFrameSize * 4} height={universalFrameSize} style={{border: '2px solid black' }} />
                </Box>

                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Attacking</Heading>
                    <canvas ref={attackingRef} width={universalFrameSize * 4} height={universalFrameSize} style={{border: '2px solid black' }} />
                </Box>

                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Shooting</Heading>
                    <canvas ref={shootingRef} width={universalFrameSize * 4} height={universalFrameSize} style={{border: '2px solid black' }} />
                </Box>

                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Casting</Heading>
                    <canvas ref={castingRef} width={universalFrameSize * 4} height={universalFrameSize} style={{border: '2px solid black' }} />
                </Box>

                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Death</Heading>
                    <canvas ref={deathRef} width={universalFrameSize} height={universalFrameSize} style={{border: '2px solid black' }} />
                </Box>

                <Box w="50%">
                    <Heading as="h2" size="md" mb={4} mt={6}>Reference Sheet</Heading>
                    <canvas ref={mainRef} width={universalSheetWidth} height={universalSheetHeight} style={{width: '100%', border: '2px solid black' }} />
                </Box>
            </VStack>
        </Flex>
    );
};